<template>
  <div>
    <el-drawer
      :title="title"
      :before-close="handleClose"
      :visible.sync="dialog"
      direction="rtl"
      custom-class="demo-drawer"
      ref="drawer"
    >
      <div>
        <el-row :gutter="24">
          <el-col :span="6">
            <el-button @click="addDataInfo">新增</el-button>
          </el-col>
        </el-row>
        <el-table height="600" :data="dataInfoList" border stripe>
          <el-table-column label="序号" :index="indexMethod" fixed type="index" />
          <el-table-column
            label="操作"
            align="center"
            class-name="small-padding fixed-width"
            width="120"
            fixed
          >
            <template slot-scope="scope">
              <el-button
                size="mini"
                type="text"
                icon="el-icon-edit"
                v-if="!scope.row.editShow"
                @click.native.prevent="addDetailButton(scope.$index, dataInfoList)"
              >保存</el-button>
              <el-button
                size="mini"
                type="text"
                icon="el-icon-edit"
                v-else
                @click.native.prevent="editButton(scope.$index, dataInfoList)"
              >编辑</el-button>
              <el-button
                size="mini"
                type="text"
                icon="el-icon-edit"
                @click.native.prevent="deleteRow(scope.$index, dataInfoList)"
              >删除</el-button>
            </template>
          </el-table-column>

          <el-table-column label="名称" prop="name" width="120">
            <template slot-scope="scope">
              <el-input
                class="edit-input"
                v-model="scope.row.name"
                :disabled="scope.row.editShow"
                placeholder="请输入内容"
              />
            </template>
          </el-table-column>
        </el-table>
      </div>
    </el-drawer>
  </div>
</template>
<script>
export default {
  data() {
    return {
      dialog: false,
      editShow: false,
      title: "",
      ids: null,
      types: null,
      dataInfoList: []
    };
  },
  methods: {
    show(row, type) {
      this.dialog = true;
      this.ids = row.id;
      this.types = type;
      if (type == 2) {
        this.selectByIdButton();
      }
    },
    handleClose(done) {
      this.$confirm("关闭")
        .then(_ => {
          this.cancelForm();
        })
        .catch(_ => {});
    },
    cancelForm() {
      this.dialog = false;
      this.editShow = false;
      this.dataInfoList = [];
      //调用父级查询
      this.$parent.selectListTripButton();
      this.$parent.id = null;
      this.$parent.questionnaireId = null;
    },
    //查询详情
    selectByIdButton() {},
    indexMethod(index) {
      return index + 1;
    },
    deleteRow(index, rows) {
      rows.splice(index, 1);
    },
    addDataInfo() {},
    addDetailButton(row, val) {},
    editButton(row, val) {}
  }
};
</script>
<style scoped>
</style>
